import React from "react";
import Text from "antd/lib/typography/Text";
import { Input, Space, Button, Alert, Spin, message } from "antd";
import Submit from "../../components/submit/Submit";
import Constant from "../../Constant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ScoreManager from "../../components/score/ScoreManager";
import { withRouter } from "react-router";
import { enc } from "crypto-js/core";

require("./AdminHeader.css");
var aesjs = require("aes-js"); //加密

const account = "admin"; //账户
const pwd = "video-lab"; //密码

class AdminHeader extends React.Component {
  state = {
    loginError: false,
    hasLogin: false, //是否登录
    genUrl: "", //分享路径
    // http://121.40.211.133/videoTest/?file=t&e=b004af873bafcff122110daa1cc5044e9f6b217658afeac08b&type=滑动对比_拼接对比
  };

  oldAccount = ""; //用户名
  oldPwd = ""; //密码
  dirName = Constant.DEFAULT_FOLDER; //文件名
  oldEmail = Constant.DEFAULT_EMAIL; //email地址

  constructor(props) {
    super(props);
    // 本地取用户名
    let account = localStorage.getItem(Constant.KEY_ACCOUNT);
    if (account) {
      this.oldAccount = account;
    }
    // 本地取密码
    let password = localStorage.getItem(Constant.KEY_PASSWORD);
    if (password) {
      this.oldPwd = password;
    }
    // 本地取登录状态
    let hasLogin = localStorage.getItem(Constant.KEY_LOGIN_STATUS);
    if (hasLogin) {
      this.state.hasLogin = true;
    }
    // 本地取文件名
    let dirname = localStorage.getItem(Constant.KEY_DIR);
    if (dirname) {
      this.dirName = dirname;
    }
    // 本地取email地址
    let email = localStorage.getItem(Constant.KEY_EMAIL);
    if (email) {
      this.oldEmail = email;
    }
  }

  render() {
    let that = this;
    // 用户名变化事件
    function onAccountChange(e) {
      if (e.target.value && e.target.value != that.oldAccount) {
        that.oldAccount = e.target.value;
      }
    }
    // 密码改变事件
    function onPasswordChange(e) {
      if (e.target.value && e.target.value != that.oldPwd) {
        that.oldPwd = e.target.value;
      }
    }
    // 登录事件
    function onClicklogin() {
      if (that.oldAccount == "") {
        message.warn("请输入账号");
        return;
      }
      if (that.oldPwd == "") {
        message.warn("请输入密码");
        return;
      }
      //检查账号信息
      if (that.oldAccount != account || that.oldPwd != pwd) {
        localStorage.removeItem(Constant.KEY_LOGIN_STATUS);
        that.props.loginCallback && that.props.loginCallback(false);

        message.error("账号或密码错误");
        that.setState({
          hasLogin: false,
        });
      } else {
        // 本地存储保存
        localStorage.setItem(Constant.KEY_LOGIN_STATUS, "login");
        localStorage.setItem(Constant.KEY_ACCOUNT, that.oldAccount);
        localStorage.setItem(Constant.KEY_PASSWORD, that.oldPwd);
        that.props.loginCallback && that.props.loginCallback(true);

        that.setState({
          loginError: false,
          hasLogin: true,
        });
      }
    }
    //文件名变化以后更改本地存储
    function onDirChange(e) {
      if (that.dirName == e.target.value) {
        return;
      }
      if (e.target.value && e.target.value != "") {
        that.dirName = e.target.value;
        localStorage.setItem(Constant.KEY_DIR, that.dirName);
        that.props.changeDirCallback &&
          that.props.changeDirCallback(that.dirName);
      }
    }
    //邮箱变化事件
    function onChangeEmail(e) {
      that.oldEmail = e.target.value;
      ScoreManager.instance.setEmail(that.oldEmail);
    }
    // 检验邮箱地址
    function checkEmail(email) {
      var pattern =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      return pattern.test(email);
    }
    //推出登录事件
    function clickLogout() {
      that.props.history.push('/login')
      localStorage.clear();
    
    }

    function clickCreateUrl() {
      //检查邮箱地址是否正确
      if (!checkEmail(that.oldEmail)) {
        message.warn("请输入正确的邮箱地址");
        return;
      }

      // 检查是否选择对比方式
      var choiceTypes = JSON.parse(sessionStorage.getItem("choiceType"));
      if (
        choiceTypes == "" ||
        choiceTypes == null ||
        choiceTypes == "underfind"
      ) {
        message.warn("请选择对比方式");
        that.setState({
          genUrl: "",
        });
        return;
      }

      //保存邮箱地址到本地
      localStorage.setItem(Constant.KEY_EMAIL, that.oldEmail);

      //邮箱使用AES加密
      var textBytes = aesjs.utils.utf8.toBytes(that.oldEmail);
      var aesCtr = new aesjs.ModeOfOperation.ctr(
        Constant.AES_128,
        new aesjs.Counter(5)
      );
      var encryptedBytes = aesCtr.encrypt(textBytes);
      var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
      var encryptedDir = "";

      fetch('https://subject-eval.aliyuncs.com/apis/share/?file=' + that.dirName).then((req) => {
        return req.json()
      }, (e) => {
        console.log(e);
      }).then((data) => {
        let code = data.code;
        if(code == 200)
        {
          encryptedDir = data.data;
          let urlPath =
            'https://subject-eval.aliyuncs.com' +
            "?" +
            "file=" +
            data.data +
            "&e=" +
            encryptedHex +
            "&type=" +
            choiceTypes;
            that.setState({
              genUrl: urlPath,
            });
        }
        else
        {
          message.error(data.data);
        }
      })
    }

    return  (
      <Space>
        <Button type="default" onClick={clickLogout}>
          退出登录
        </Button>

        <Text style={{ color: "#fff" }}>OSS文件夹：</Text>
        <Input
          key="folder"
          type="url"
          placeholder="请输入文件夹，如video_taobao"
          onChange={onDirChange}
          defaultValue={this.dirName}
        ></Input>

        <Text style={{ color: "#fff" }}>接受分数的邮箱：</Text>
        <Input
          placeholder="请输入接收分数的邮箱地址"
          defaultValue={this.oldEmail}
          onChange={onChangeEmail}
        ></Input>

        <Button type="primary" onClick={clickCreateUrl}>
          生成链接
        </Button>

        {this.state.genUrl != "" && (
          <CopyToClipboard
            text={this.state.genUrl}
            onCopy={() => message.info("已复制链接")}
          >
            <Text className="urlText">{"点击复制：" + this.state.genUrl}</Text>
          </CopyToClipboard>
        )}

        <div className="dirlayout">
          <Submit key="admin_submit"></Submit>
        </div>
      </Space>
    ) 
  }
}

export default withRouter(AdminHeader) ;
