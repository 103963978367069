import { Layout, Button } from "antd";
import React from "react";
import NormalHeader from "./NormalHeader";
import HomeContent from "../home/HomeContent";
import ScoreManager from "../../components/score/ScoreManager";
import Constant from "../../Constant";

var aesjs = require("aes-js");

const { Header, Content } = Layout;

/**
 * 普通评测员页面
 */
class Normal extends React.Component {
  state = {
    isFile: "",
  };

  componentDidMount(props) {
    let urlArr = window.location.search;
    if (urlArr) {
      this.findEmail(urlArr);
    } else {
      let urls = localStorage.getItem("urls");
      this.findEmail(urls);
    }
  }

  findEmail = (props) => {
    var para = new URLSearchParams(props);
    const encryptedHex = para.get("e");
    const encryptedHexFile = para.get("file");
    localStorage.setItem("KEY_dir", encryptedHexFile);
    setTimeout(() => {
      this.setState({
        isFile: encryptedHexFile,
      });
    }, 0);
    var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
    var aesCtr = new aesjs.ModeOfOperation.ctr(
      Constant.AES_128,
      new aesjs.Counter(5)
    );
    var decryptedBytes = aesCtr.decrypt(encryptedBytes);
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    ScoreManager.instance.setEmail(decryptedText);
  };

  render() {
    return (
      <Layout>
        <Header>
          <NormalHeader />
        </Header>
        <Content>
          <HomeContent folder={this.state.isFile} is_encrypt="true"/>
        </Content>
      </Layout>
    );
  }
}

export default Normal;
