import CryptoJS from "crypto-js";

export function getNewData(result) {
  function deepClone2(obj) {
    var _obj = JSON.stringify(obj),
      objClone = JSON.parse(_obj);
    return objClone;
  }

  let newResult = deepClone2(result);
  
  let newObj = {};
  newResult.map((ele, i) => {
    const newEle = ele.url.split("/");
    let a = newEle[newEle.length - 1].split(".");

    a.pop(a.length - 1);
    let b = a.join("").split("_");
    b.pop(a[a.length - 1]);
    let c = b.join("");

    if (!newObj[c]) {
      let arr = [];
      newObj[c] = arr;
      arr.push(i);
    } else {
      newObj[c].push(i);
    }
  });
  const newArry = [];
  for (const key in newObj) {
    if (Object.hasOwnProperty.call(newObj, key)) {
      const ele = newObj[key];
      let arr = [];
      ele.forEach((element, index) => {
        arr.push(result[element]);
        if (index === 0) {
          result[element].a_val = 0;
        } else {
          result[element].b_val = 0;
        }
      });
      // if (arr.length === 2 || arr.length === 3) {
        
      // }
      newArry.push(arr);
    }
  }

  newArry.map((item) => {
    for (const key in item) {
      const a = item[key];
      const b = a.name.split("/");
      const c = b[b.length - 1];
      item[key].name = c;
    }
  });

  const newArry1 = newArry.sort(function (a, b) {
    const valueA = parseInt(a[0].name);
    const valueB = parseInt(b[0].name);
    return valueA - valueB;
  });

  return newArry1;
}

export function createHash(hashLength) {
  if (!hashLength || typeof Number(hashLength) != "number") {
    return;
  }
  var ar = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
  var hs = [];
  var hl = Number(hashLength);
  var al = ar.length;
  for (var i = 0; i < hl; i++) {
    hs.push(ar[Math.floor(Math.random() * al)]);
  }

  return hs.join("");
}

export function downloadFile(url = "", fileName = "") {
  var a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

/**
 * 生成指定区间的随机整数
 * @param min
 * @param max
 * @returns {number}
 */
export function randomNum(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

/**
 * 加密函数，加密同一个字符串生成的都不相同
 * @param {*} str
 */
export function encrypt(str) {
  return CryptoJS.AES.encrypt(JSON.stringify(str), "front_666666").toString();
}

/**
 * 防抖函数
 * @param {*} func
 * @param {*} wait
 */
export function debounce(func, wait = 500) {
  let timeout; // 定时器变量
  return function (event) {
    clearTimeout(timeout); // 每次触发时先清除上一次的定时器,然后重新计时
    event.persist && event.persist(); //保留对事件的引用
    timeout = setTimeout(() => {
      func(event);
    }, wait); // 指定 xx ms 后触发真正想进行的操作 handler
  };
}

export function setitem(key, vlaue) {
  localStorage.setItem(key, vlaue);
}
