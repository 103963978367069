 import React from "react";

require("./OssItem.css");

class OssItem extends React.Component {

    render() {
        var that = this;

        function onClickItem() {
            that.props.clickItemCallback && that.props.clickItemCallback(that.props.index);
        }

        return (
            <div onClick={onClickItem} className={this.props.select ? "OssItemLayoutSelect" : "OssItemLayout"}>
                <p className="index">{this.props.index}</p>

                <p className="name">{this.props.name}</p>

                <p className="score">{this.props.leftScore}</p>

                <p className="score">{this.props.rightScore}</p>
            </div>
        )
    }
}

export default OssItem;