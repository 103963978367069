import {
  Alert,
  Button,
  InputNumber,
  List,
  Select,
  Slider,
  Menu,
  Dropdown,
  message,
  Checkbox,
  Radio,
  Descriptions,
  Form
} from "antd";
import {
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  FullscreenOutlined,
  FullscreenExitOutlined,
  RetweetOutlined,
  DownloadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import ScoreManager from "../../components/score/ScoreManager";
import ListHeader from "./ListHeader";
import OssItem from "./OssItem";
import Text from "antd/lib/typography/Text";
import { DownOutlined } from "@ant-design/icons";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import videoPlay from "../../img/video_play.png";
import videoPause from "../../img/video_pause.png";
import moveLeftIcon from "../../img/left_icon.png";
import { getNewData, downloadFile, createHash } from "../../utils/utils";
require("./HomeContent.css");

/**
 * 视频列表及打分的布局
 */


class HomeContent extends React.Component {
  state = {
    rowOrClo: "1",
    ossFileList: [],
    playIndex: 0, //播放視頻索引
    playUrlA: "", //a視頻地址
    playUrlB: "", //b視頻地址
    playUrlS: "", //C视频地址
    fileName: "", //視頻文件名
    scoreLeft: 0, //左边的分数
    scoreRight: 0, //右边的分数
    translateX: "50%", //顶部视频移动频移距离
    topVideoWidth: 0, //顶部视频宽
    topVideoHeight: 0, //顶部视频高度
    protoWidth: 0, //C始宽度
    protoHeight: 0, //C始高度
    boxWidth: 0, //视频盒子宽高
    playerHeigt: 0,
    playerweight: 0,
    protoShow: false, //C始宽高显示
    isOn: true, //是否播放
    vcCurrentTime: "00:00", //当前进度
    vcTotalTime: "00:00", //总时长
    usetimeResult: null,
    timer: null, //定时器
    videoNowSed: 0, //控制条当前值（秒数）
    videoAllSed: 0, //控制条最大值（秒数）
    videoProgressSuccess: false, //视频是否加载完成
    oldVideoWidth: 0, // 切换对比，视频盒子的宽度
    oldVideoHeight: 0, // 切换对比，视频盒子的宽度
    videoSize: 0, //0：滑动对比， 1：左右/上下对比，2：全屏对比
    bodyClientWidth: 0, // 可见页面宽度
    bodyClientHeight: 0, // 可见页面高度
    nowVideoIndex: 0, // 当前视频索引
    getTypes: "", // 分享获得对比方式
    currentTyes: "", //当前对比方式
    typeBox: [], //初始对比方式
    Yvisibility: null,
    alertTitle: "",
    canBeUse: true, //切图工具是否被使用
    magnifier_show: "none", //放大镜页面是否被隐藏
    magnifier_width: 0, //放大镜的宽
    magnifier_hight: 0, //放大镜的高
    magnifier_startX: 0, //放大镜的起始X坐标
    magnifier_startY: 0, //放大镜的起始Y坐标
    magnifier_OffY: 0, //放大镜偏移Y的距离
    magnifier_OffX: 0, //放大镜偏移X的距离
    magnifier_up: false,
    magnifier_size: true,
    video_box_left: null,
    video_box_top: null,
    Check_box_border_offsetTop: 0, //选框X方向的偏移量
    Check_box_border_offsetLefg: 0, //选框Y方向的偏移量
    Check_box_border_width: 0, //选框宽度
    Check_box_border_hight: 0, //选框高度
    Check_box_border_show: "none", //选框是否显示
    Check_box_border_is_show: false, //选框标识
    source: false, //C视频显示标识
    moveTrue: false, //标识线是否移动
    screenFullShow: false, //视频是否全屏展示
    imgAurl: "",
    imgBurl: "",
    imgSurl: "",
    downTrue: false,
    contrlShow: "hidden",
    keyCanPress: false,
    vdieoIndexTag: 0,
    AIndex: 0,
    BIndex: 1,
    CIndex: 2,
    LPressed: false
  };

  constructor(props) {
    super(props);
    this.readDirFromUrl(props);
    this.myVideo = React.createRef();
    this.videoTop = React.createRef();
    this.yMove = React.createRef();
    this.noneVideo = React.createRef();
    this.mySelect = React.createRef();
    this.videoBox = React.createRef();
  }

  componentDidMount() {

    this.getType(); //视频对比方式
    // this.list(this.props.folder); //list 请求接口
    this.getWindowInfo(); // 获取浏览器宽高
    window.addEventListener("keydown", this.handleKeyDown); //键盘事件
    window.addEventListener("keyup", this.handleKeyUp); //键盘事件
    window.addEventListener("resize", this.handleResize); //窗口变化事件
    var a = document.getElementsByTagName("video");
    this.getWaitingTime(this.props.folder);

    if (this.props.folder.length !== 0) {
      this.get_url_list(this.props.folder);
    }

    document.addEventListener(
      "webkitfullscreenchange",
      (event) => {
        if (document.webkitIsFullScreen === false) {
          this.setState(
            {
              screenFullShow: false,
              protoShow: false,
            },
            () => {
              this.calculateFun();
            }
          );
        } else {
          return;
        }
      },
      false
    );

    window.addEventListener("resize", function () {
      //监听屏幕
      monitorScreen();
    });
    function monitorScreen() {
      if (window.orientation == 0) {
        //console.log("竖屏");
      } else if (window.orientation == 90) {
        //console.log("横屏");
      }
    }

    window.addEventListener("mouseup", () => {
      this.videoBoxUp();
      this.setState({
        downTrue: false,
      });
    });

    window.addEventListener("mousemove", (e) => {
      if (document.documentElement.clientHeight - 50 <= e.clientY) {
        this.setState({
          contrlShow: "visible",
        });
      } else {
        this.setState({
          contrlShow: "hidden",
        });
      }
    });

    //视频暂停播放

    for (let i = 0; i < a.length - 1; i++) {
      a[i].setAttribute("crossorigin", "Anonymous");
      a[i].addEventListener(
        "pause",
        () => {
          this.setState({
            isOn: true,
          });
          this.getWaitingTime();
        },
        false
      );

      a[i].addEventListener(
        "waiting",
        () => {
          setTimeout(() => {
            this.playerA.pause();
          }, 0);
          setTimeout(() => {
            this.playerB.pause();
          }, 0);

          setTimeout(() => {
            this.playerS.pause();
          }, 0);
          this.getWaitingTime();
        },
        false
      );

      a[i].addEventListener(
        "seeking",
        () => {
          setTimeout(() => {
            this.playerA.pause();
            this.getWaitingTime();
          }, 0);
          setTimeout(() => {
            this.playerB.pause();
            this.getWaitingTime();
          }, 0);
          setTimeout(() => {
            this.playerS.pause();
            this.getWaitingTime();
          }, 0);
        },
        false
      );

      //视频C数据加载完成，將視頻進度條与外观进度条同步
      a[i].addEventListener(
        "canplaythrough",
        (e) => {
          const { topVideoWidth, topVideoHeight, playUrlS } = this.state;
          if (topVideoWidth === 0 && topVideoHeight === 0) {
            var videoBottomCurtime = e.target.currentTime; //底层视频当前时间
            var videoBottomDuration = e.target.duration; //底层视频总时长
            var vcCurrentTimes = this.getFormatVideoTime(videoBottomCurtime); //页面展示当前时间：00：00
            var vcTotalTimes = this.getFormatVideoTime(videoBottomDuration); //页面展示总时间：00：15
            this.setState(
              {
                vcCurrentTime: vcCurrentTimes,
                vcTotalTime: vcTotalTimes,
                videoNowSed: 0,
                videoAllSed: videoBottomDuration,
                protoWidth: e.target.videoWidth,
                protoHeight: e.target.videoHeight,
              },
              () => {
                this.calculateFun();

              }
            );
          }
          this.setState({
            videoProgressSuccess: true,
          });
          this.getDurition();
        },
        false
      );

      //视频开始播放时触发
      a[i].addEventListener(
        "play",
        (e) => {
          this.setState(
            {
              isOn: false,
            },
            () => {
              this.getWaitingTime();
            }
          );
        },
        false
      );

      //视频正在播放
      a[i].addEventListener(
        "timeupdate",
        (e) => {
          var vcCurrentTime = this.getFormatVideoTime(e.target.currentTime);
          if (!e.target.ended) {
            this.setState({
              vcCurrentTime,
              videoNowSed: e.target.currentTime,
            });
          } else {
            this.setState({
              vcCurrentTime,
              videoNowSed: e.target.currentTime,
            });
            clearInterval(this.timer);
            this.setState({
              timer: null,
            });
          }
        },
        false
      );
    }


  }

  readDirFromUrl(props) {
    this.dirName = props.folder;

  }

  componentWillReceiveProps(props) {
    const newProp = props.folder;
    if (newProp !== "") {
      if (props.is_encrypt == undefined) {
        this.get_url_list(props.folder);
      }
      else {
        this.get_url_list(props.folder, props.is_encrypt);
      }
    }
  }

  componentWillUpdate() {
    for (let i = 0; i < this.state.ossFileList.length; i++) {
      const videos = this.state.ossFileList[i];

      var nowVideoName = "";
      var saveName = "";
      saveName = videos[0].url.split(".");
      saveName = saveName[saveName.length - 1];
      nowVideoName = this.dirName + "/" + videos[0].name + "." + saveName;
      ScoreManager.instance.put(nowVideoName, videos[0].a_val, videos[1].b_val);
    }

    window.onresize = () => {
      this.handleResize();
      this.getWindowInfo();
      this.jietu();


    };
  }


  //视频对比方式
  getType = () => {
    var getTypes = decodeURIComponent(window.location.href.split("&type=")[1]);
    if (getTypes == "滑动对比") {
      this.setState({
        typeBox: [{ value: "0", name: "滑动对比" }],
        videoSize: 0,
        getTypes,
      });
    } else if (getTypes == "拼接对比") {
      this.setState({
        typeBox: [{ value: "1", name: "拼接对比" }],
        videoSize: 1,
        getTypes,
      });
    } else {
      this.setState({
        typeBox: [
          { value: "0", name: "滑动对比" },
          { value: "1", name: "拼接对比" },
        ],
        videoSize: 0,
        getTypes,
      });
    }

    this.setState({
      currentTyes: getTypes,
    });
  };

  //对比视频总时长
  getDurition = () => {
    setTimeout(() => {
      const a = this.playerA.getState().player.duration;
      const b = this.playerB.getState().player.duration;
      if (a !== b) {
        this.setState({
          alertTitle: `！注意您的A视频总时长${a}秒不等于B视频的总时长${b}，相差${(
            a - b
          ).toFixed(4)}秒，可能会导致视频对不齐的现象`,
        });
      } else {
        this.setState({
          alertTitle: "",
        });
      }
    }, 500);
  };

  //键盘事件
  handleKeyDown = (e) => {
    let { canBeUse, videoNowSed, source, videoAllSed, isOn, videoSize, keyCanPress, rowOrClo, } = this.state
    //console.log(e.keyCode);
    if (keyCanPress) {
      if (e.keyCode === 9 && videoSize == 1) {
        this.setState({
          rowOrClo: rowOrClo == '1' ? '2' : '1'
        }, () => {
          this.calculateFun()
        })
      }

      if (canBeUse && e.keyCode === 32) {
        e.preventDefault();
        this.setVideos();
      }
      if (e.keyCode === 76 && !source) {

        this.sourceShow()



      }

      if (e.keyCode === 49) {

        this.onVideoSizeChange(0)

      }
      if (e.keyCode === 50) {

        this.onVideoSizeChange(1)

      }

      if (e.keyCode === 187 && this.state.playUrlS && this.state.videoSize == 0) {

        let { vdieoIndexTag } = this.state
        vdieoIndexTag = vdieoIndexTag + 1 > 2 ? 0 : vdieoIndexTag + 1
        this.setState({
          vdieoIndexTag
        })
        switch (vdieoIndexTag) {
          case 0:
            this.setState({
              AIndex: 0,
              BIndex: 1,
              CIndex: 2
            })
            break;
          case 1:
            this.setState({
              AIndex: 1,
              BIndex: 2,
              CIndex: 0
            })
            break;
          case 2:
            this.setState({
              AIndex: 2,
              BIndex: 0,
              CIndex: 1
            })
            break;

          default:
            this.setState({
              AIndex: 0,
              BIndex: 1,
              CIndex: 2
            })
            break;
        }


      }

      if (e.keyCode === 189 && this.state.playUrlS && this.state.videoSize == 0) {
        let { vdieoIndexTag } = this.state
        vdieoIndexTag = vdieoIndexTag - 1 < 0 ? 2 : vdieoIndexTag - 1
        this.setState({
          vdieoIndexTag
        })
        switch (vdieoIndexTag) {
          case 0:
            this.setState({
              AIndex: 0,
              BIndex: 1,
              CIndex: 2
            })
            break;
          case 1:
            this.setState({
              AIndex: 1,
              BIndex: 2,
              CIndex: 0
            })
            break;
          case 2:
            this.setState({
              AIndex: 2,
              BIndex: 0,
              CIndex: 1
            })
            break;

          default:
            this.setState({
              AIndex: 0,
              BIndex: 1,
              CIndex: 2
            })
            break;
        }

      }


      if (e.keyCode === 39) {
        e.preventDefault();
        var e = videoNowSed + 0.2;
        if (e === videoAllSed) {
          return;
        }
        this.changeSed(e);
        this.setVideos();
      }

      if (e.keyCode === 37) {
        e.preventDefault();
        var e = videoNowSed - 0.2;
        if (e === 0) {
          return;
        }
        this.changeSed(e);
        this.setVideos();
      }

      if (
        isOn &&

        e.keyCode === 67 &&
        // e.keyCode === 67 &&
        videoSize == 0
      ) {
        this.setState({
          canBeUse: false,
          // translateX: "50%",
        });
        e.preventDefault();
      }
      if (e.keyCode === 81) {
        this.closeMagnifier();
        e.preventDefault();
      }
    }
    if (e.keyCode === 27) {
      if (!canBeUse) {
        this.setState(
          {
            screenFullShow: false,
          },
          () => {
            this.calculateFun();
          }
        );
      } else {
        //console.log(2);
      }


      e.preventDefault();
    }
  };


  handleKeyUp = (e) => {
    if (this.state.source && e.keyCode == 76) {

      this.sourceShow()

    }
  }
  // 关闭放大镜事件
  closeMagnifier = () => {
    this.setState({
      canBeUse: true,
      magnifier_show: "none",
      translateX: "50%",
      magnifier_up: false,
      Check_box_border_show: "none",
      magnifier_width: 0, //放大镜的宽
      magnifier_hight: 0, //放大镜的高
      magnifier_startX: 0, //放大镜的起始X坐标
      magnifier_startY: 0, //放大镜的起始Y坐标
      Check_box_border_width: 0,
      Check_box_border_hight: 0,
    });
  };

  // 获取浏览器宽高
  handleResize = () => {
    //console.log(document.body.clientWidth);
    //console.log(document.body.clientHeight);
    this.setState(
      {
        bodyClientWidth: document.body.clientWidth,
        bodyClientHeight: document.body.clientHeight,
      },
      () => {
        this.calculateFun(); //计算视频宽高
      }
    );
  };

  // 获取浏览器宽高
  getWindowInfo = () => {
    this.setState({
      bodyClientWidth: document.body.clientWidth,
      bodyClientHeight: document.body.clientHeight,
    });
  };
  //两个视频加载等待同步
  getWaitingTime = () => {
    let timeA = this.playerA.getState().player.currentTime;
    let timeB = this.playerB.getState().player.currentTime;
    let timeResult = true;
    if (timeA != timeB) {
      if (timeA > timeB) {
        timeResult = timeB;
      } else if (timeB > timeA) {
        timeResult = timeA;
      }
      setTimeout(() => {
        this.playerA.getState().player.currentTime = timeResult;
        this.setState({
          usetimeResult: timeResult,
        });
      }, 0);
      setTimeout(() => {
        this.playerB.getState().player.currentTime = timeResult;
      }, 0);
    }
    this.setState({
      usetimeResult: timeA,
    });
  };

  get_url_list(file_path, is_encrypt = "false") {
    //console.log(file_path, is_encrypt);
    fetch('/apis/api/?file=' + file_path + '&encrypt=' + is_encrypt).then((req) => {
      return req.json()
    }, (e) => {
      //console.log(e);
    }).then((data) => {
      let result = data?.data
      if (data?.code !== 200) {
        message.error(data?.data)
        return;
      }
      //console.log(result);
      this.setState({
        topVideoWidth: 0,
        topVideoHeight: 0,
      });
      var newVideoArr = [];


      if (result == "" || result == null || result == "underfind") {
        return;
      } else {
        if (result.length == 0) {
          this.setState({
            ossFileList: newVideoArr,
            videoTagList: [],
            playIndex: 0,
            fileName: "",
          });
          return;
        } else {
          console.log("== wz result", result)
          newVideoArr = getNewData(result);
          console.log("== wz newVideoArr", newVideoArr)
          if (newVideoArr.length === 0) {
            return;
          }
          let fileName = newVideoArr[0][0].name;
          let playUrlA = newVideoArr[0][0].url;
          let playUrlB = newVideoArr[0][1].url;
          let playUrlS = newVideoArr[0][2] ? newVideoArr[0][2].url : "";
          this.setState({
            ossFileList: newVideoArr,
            playIndex: 0,
            videoTagList: newVideoArr[0],
            fileName: fileName,
            playUrlA,
            playUrlB,
            playUrlS,
          });
        }
      }
    })
  }

  /**
   * 修改显示的视频
   * @param {*} newIndex
   */
  changeVideo = (newIndex) => {
    if (newIndex == this.state.playIndex) {
      return;
    }

    if (newIndex >= this.state.ossFileList.length) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = this.state.ossFileList.length - 1;
    }
    if (this.state.ossFileList[newIndex] == undefined) {
      return;
    }

    let fileName = this.state.ossFileList[newIndex][0].name;
    // abs为播放链接
    let playUrlA = this.state.ossFileList[newIndex][0].url;
    let playUrlB = this.state.ossFileList[newIndex][1].url;
    let playUrlS = this.state.ossFileList[newIndex][2]
      ? this.state.ossFileList[newIndex][2].url
      : "";

    this.setState({
      playIndex: newIndex,
      fileName: fileName,
      videoTagList: [],
      playUrlA,
      playUrlB,
      playUrlS,

      scoreLeft: this.state.ossFileList[newIndex][0].a_val,
      scoreRight: this.state.ossFileList[newIndex][1].b_val,
      translateX: "50%",
      vcCurrentTime: "00:00",
      vcTotalTime: "00:00",
      videoNowSed: 0,
      videoProgressSuccess: false,
      topVideoWidth: 0,
      topVideoHeight: 0,
      nowVideoIndex: newIndex,
      AIndex: 0,
      BIndex: 1,
      CIndex: 2

    }, () => {
      this.setState(
        {
          videoTagList: this.state.ossFileList[newIndex],
        }
      )
    });

    this.setState({
      isOn: true,
    });


  };

  // 拖拽条移动
  small_down = (e) => {

    e.preventDefault();
    var obig = this.yMove.current.parentNode;
    var osmall = this.yMove.current;
    var evt = e || window.event;
    /*用于保存小的div拖拽前的坐标*/
    osmall.startX = evt.pageX - osmall.offsetLeft;
    osmall.startY = evt.pageY - osmall.offsetTop;
    /*鼠标的移动事件*/
    document.onmousemove = (e) => {

      var evt = e || window.event;
      osmall.style.left = evt.pageX - osmall.startX + "px";
      /*对于大的DIV四个边界的判断*/
      let x = obig.offsetWidth - osmall.offsetWidth;
      if (evt.pageX - osmall.startX <= 0) {
        osmall.style.left = -1 + "px";
      }
      if (evt.pageX - osmall.startX >= x) {
        osmall.style.left = x + "px";
      }
    };
    /*鼠标的抬起事件,终止拖动*/
    document.onmouseup = function () {
      document.onmousemove = null;
      document.onmouseup = null;
    };
  };

  //计算视频宽高
  calculateFun = () => {

    const {
      bodyClientWidth,
      bodyClientHeight,
      videoSize,
      protoWidth,
      protoHeight,
      screenFullShow,
      protoShow,
      rowOrClo,
      playUrlS

    } = this.state;
    var changeWidth = 0; //计算后的宽度
    var changeHeight = 0; //计算后的高度
    var videoDivWidth = 0; //切换测评方式，视频盒子的宽度
    var pageOrderWidth = 380 + 50 + 40; // 视频窗口外其他区域的宽度
    //视频宽度大于视频高度，例：1280 * 720
    // 720 / 1280 = 0.5625
    // xx / maxWidth = 0.5625

    if (playUrlS && videoSize == 1) {
      this.setState({
        source: true
      })
    } else {
      this.setState({
        source: false
      })
    }

    if (videoSize == 0 && protoWidth > protoHeight) {
      var result = protoHeight / protoWidth; //视频缩放比例：高 / 宽
      if (screenFullShow && !protoShow) {
        var maxWidth = bodyClientWidth;
        videoDivWidth = maxWidth;
        changeWidth = maxWidth;
        changeHeight = maxWidth * result;
        var playerHeigts = bodyClientHeight;
        var playerwidths = bodyClientWidth;
      } else if (screenFullShow && protoShow) {
        var maxWidth = bodyClientWidth;
        videoDivWidth = maxWidth;
        changeWidth = protoWidth;
        changeHeight = protoHeight;
        var playerHeigts = bodyClientHeight;
        var playerwidths = bodyClientWidth;
      } else {
        if (protoWidth + pageOrderWidth > bodyClientWidth) {
          //视频列表 + 视频区域的宽度大于页面总宽度
          var maxWidth = bodyClientWidth - pageOrderWidth - 17;
          videoDivWidth = maxWidth;
          changeWidth = maxWidth;
          changeHeight = maxWidth * result;
        } else {
          videoDivWidth = protoWidth;
          changeWidth = protoWidth;
          changeHeight = protoHeight;
        }
      }
    } else if (videoSize == 0 && protoWidth <= protoHeight) {
      var result = protoWidth / protoHeight; //视频缩放比例：高 / 宽
      if (screenFullShow && !protoShow) {
        var maxHeight = bodyClientHeight;

        videoDivWidth = maxHeight;
        changeWidth = maxHeight * result;
        changeHeight = maxHeight;
        var playerHeigts = bodyClientHeight;
        var playerwidths = bodyClientWidth;
      } else if (screenFullShow && protoShow) {
        videoDivWidth = maxWidth;
        changeWidth = protoWidth;
        changeHeight = protoHeight;
        var playerHeigts =
          protoHeight > bodyClientHeight ? protoHeight : bodyClientHeight;
        var playerwidths = bodyClientWidth;
      } else {
        if (protoWidth + pageOrderWidth > bodyClientWidth) {
          //视频列表 + 视频区域的宽度大于页面总宽度
          var maxWidth = bodyClientWidth - pageOrderWidth;
          videoDivWidth = maxWidth;
          changeWidth = maxWidth;
          changeHeight = maxWidth / result;
        } else {
          videoDivWidth = protoWidth;
          changeWidth = protoWidth;
          changeHeight = protoHeight;
        }
      }
    } else if (videoSize == 1 && rowOrClo == '1') {
      var result = protoHeight / protoWidth; //视频缩放比例：高 / 宽
      if (screenFullShow && !protoShow) {
        if (playUrlS) {
          var maxRightWidth = bodyClientWidth;
          changeHeight = bodyClientHeight / 3;
          changeWidth = changeHeight / result;
          var playerHeigts = bodyClientHeight;
          var playerwidths = bodyClientWidth;
        } else {
          var maxRightWidth = bodyClientWidth;
          changeHeight = bodyClientHeight / 2;
          changeWidth = changeHeight / result;
          var playerHeigts = bodyClientHeight;
          var playerwidths = bodyClientWidth;
        }

      } else if (screenFullShow && protoShow) {
        if (playUrlS) {
          if (protoHeight * 3 <= bodyClientHeight) {
            var playerHeigts = bodyClientWidth;
          } else {
            var playerHeigts = protoHeight * 3;
          }
          changeWidth = protoWidth;
          changeHeight = protoHeight;
          videoDivWidth = bodyClientWidth;

          var playerwidths = bodyClientWidth;
        } else {
          if (protoHeight * 2 <= bodyClientHeight) {
            var playerHeigts = bodyClientWidth;
          } else {
            var playerHeigts = protoHeight * 2;
          }
          changeWidth = protoWidth;
          changeHeight = protoHeight;
          videoDivWidth = bodyClientWidth;

          var playerwidths = bodyClientWidth;
        }

      } else {

        if (playUrlS) {
          var maxRightWidth = bodyClientWidth - pageOrderWidth - 17;
          var maxVideoHeight = (bodyClientHeight - 40 - 80 - 32 - 20) / 3;
          var maxVideoWidth = maxVideoHeight / result;
          if (maxRightWidth > maxVideoWidth) {
            videoDivWidth = maxRightWidth;
            changeWidth = maxVideoWidth;
            changeHeight = maxVideoHeight;
          } else {
            videoDivWidth = maxRightWidth;
            changeWidth = maxRightWidth;
            changeHeight = changeWidth * result;
          }
        } else {
          var maxRightWidth = bodyClientWidth - pageOrderWidth - 17;
          var maxVideoHeight = (bodyClientHeight - 40 - 80 - 32 - 20) / 2;
          var maxVideoWidth = maxVideoHeight / result;
          if (maxRightWidth > maxVideoWidth) {
            videoDivWidth = maxRightWidth;
            changeWidth = maxVideoWidth;
            changeHeight = maxVideoHeight;
          } else {
            videoDivWidth = maxRightWidth;
            changeWidth = maxRightWidth;
            changeHeight = changeWidth * result;
          }
        }

      }
    } else if (videoSize == 1 && rowOrClo == '2') {
      var result = protoWidth / protoHeight; //视频缩放比例：高 / 宽
      if (screenFullShow && !protoShow) {

        if (playUrlS) {
          if (protoWidth <= protoHeight) {
            var maxHeight = bodyClientHeight;

            videoDivWidth = maxHeight;
            changeHeight = maxHeight;
            changeWidth = maxHeight * result;
            playerHeigts = bodyClientHeight;
            playerwidths = bodyClientWidth;
          } else {
            var maxHeight = bodyClientHeight;

            videoDivWidth = maxHeight;

            changeWidth = bodyClientWidth / 3;
            changeHeight = changeWidth / result;
            playerHeigts = bodyClientHeight;
            playerwidths = bodyClientWidth;
          }
        } else {
          if (protoWidth <= protoHeight) {
            var maxHeight = bodyClientHeight;

            videoDivWidth = maxHeight;
            changeHeight = maxHeight;
            changeWidth = maxHeight * result;
            playerHeigts = bodyClientHeight;
            playerwidths = bodyClientWidth;
          } else {
            var maxHeight = bodyClientHeight;

            videoDivWidth = maxHeight;

            changeWidth = bodyClientWidth / 2;
            changeHeight = changeWidth / result;
            playerHeigts = bodyClientHeight;
            playerwidths = bodyClientWidth;
          }

        }
      } else if (screenFullShow && protoShow) {
        if (playUrlS) {
          videoDivWidth = maxWidth;
          changeWidth = protoWidth;
          changeHeight = protoHeight;
          var boxWidth = protoWidth * 3;

          if (protoHeight > bodyClientHeight) {
            var playerHeigts = protoHeight;
          } else {
            var playerHeigts = bodyClientHeight;
          }

          if (protoWidth * 3 > bodyClientWidth) {
            var playerwidths = protoWidth * 3;
          } else {
            var playerwidths = bodyClientWidth;
          }
        } else {
          videoDivWidth = maxWidth;
          changeWidth = protoWidth;
          changeHeight = protoHeight;
          var boxWidth = protoWidth * 2;

          if (protoHeight > bodyClientHeight) {
            var playerHeigts = protoHeight;
          } else {
            var playerHeigts = bodyClientHeight;
          }

          if (protoWidth * 2 > bodyClientWidth) {
            var playerwidths = protoWidth * 2;
          } else {
            var playerwidths = bodyClientWidth;
          }
        }

      } else {

        if (playUrlS) {
          //console.log('---------', 2);
          var twoRightWidth = bodyClientWidth - pageOrderWidth - 17;
          var twoVideoHeight = bodyClientHeight - 40 - 80 - 32 - 20;
          var twoVideoWidth = twoVideoHeight * result;
          var boxWidth = twoVideoWidth;

          if (twoRightWidth > twoVideoWidth * 3) {
            videoDivWidth = twoRightWidth;
            changeHeight = twoVideoHeight;
            changeWidth = twoVideoWidth;
          } else {
            videoDivWidth = twoRightWidth;
            changeWidth = videoDivWidth / 3;
            changeHeight = changeWidth / result;
          }
        } else {
          //console.log('---------', 3);
          var twoRightWidth = bodyClientWidth - pageOrderWidth - 17;
          var twoVideoHeight = bodyClientHeight - 40 - 80 - 32 - 20;
          var twoVideoWidth = twoVideoHeight * result;
          var boxWidth = twoVideoWidth;

          if (twoRightWidth > twoVideoWidth * 2) {
            videoDivWidth = twoRightWidth;
            changeHeight = twoVideoHeight;
            changeWidth = twoVideoWidth;
          } else {
            videoDivWidth = twoRightWidth;
            changeWidth = videoDivWidth / 2;
            changeHeight = changeWidth / result;
          }
        }
      }


    }


    this.setState({
      oldVideoWidth: videoDivWidth,
      oldVideoHeight: videoDivWidth * result,
      topVideoWidth: changeWidth,
      topVideoHeight: changeHeight,
      boxWidth: boxWidth,
      playerHeigt: playerHeigts,
      playerwidth: playerwidths,
    }, () => {
      //console.log(this.state.source);
    });
  };

  //格式化时间
  getFormatVideoTime(time) {
    var m = parseInt((time % 3600) / 60),
      s = parseInt(time % 60);
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    return m + ":" + s;
  }

  //拖拽进度条
  changeSed = (e) => {
    var vcCurrentTimes = this.getFormatVideoTime(e);

    var a = document.getElementsByTagName("video");
    this.setState({
      vcCurrentTime: vcCurrentTimes,
      videoNowSed: e,
      isOn: true,
    });
    setTimeout(() => {
      a[0].currentTime = e;
      a[0].pause();
    }, 0);
    setTimeout(() => {
      a[1].currentTime = e;
      a[1].pause();
    }, 0);
    setTimeout(() => {
      a[2].currentTime = e;
      a[2].pause();
    }, 0);
  };

  // 视频播放和停止
  setVideos = () => {
    const { isOn } = this.state;
    if (this.playerA && this.playerB) {
      let timeA = this.playerA.getState().player.currentTime;
      let timeB = this.playerB.getState().player.currentTime;
      let timeS = this.playerS.getState().player.currentTime;
      let timeResult = 0;

      if (timeA > timeB) {
        timeResult = timeA;
      } else if (timeB > timeA) {
        timeResult = timeB;
      }
      if (isOn) {
        setTimeout(() => {
          this.playerA.play();
          this.playerB.play();
          this.playerS.play();
        }, 0);
      } else {
        setTimeout(() => {
          //console.log(timeResult);
          this.playerA.pause();
          this.playerB.pause();
          this.playerS.pause();
          this.playerB.seek(timeResult);
          this.playerA.seek(timeResult);
          this.playerS.seek(timeResult);
          this.setState({
            usetimeResult: timeResult,
          });
        }, 0);
        setTimeout(() => { }, 0);
      }

      if (
        this.playerA.getState().player.currentTime !==
        this.playerB.getState().player.currentTime
      ) {
        if (
          this.playerA.getState().player.currentTime >
          this.playerB.getState().player.currentTime
        ) {
          this.playerA.getState().player.currentTime =
            this.playerB.getState().player.currentTime;
        } else {
          this.playerB.getState().player.currentTime =
            this.playerA.getState().player.currentTime;
        }
      }
    }
  };

  //视频下载
  handleMenuClick = async (e) => {
    const hide = message.loading("下载中...", 0); // alias of warning
    const { ossFileList, playIndex } = this.state;
    var downloadUrl = "";
    var downloadName = "";

    if (e.key == 1) {
      downloadUrl = this.state.playUrlA.replace("https", 'http');
      downloadName = ossFileList[playIndex][0].name;
    } else if (e.key == 2) {
      downloadUrl = this.state.playUrlB.replace("https", 'http');
      downloadName = ossFileList[playIndex][1].name;
    } else {
      downloadUrl = this.state.playUrlS.replace("https", 'http');
      downloadName = ossFileList[playIndex][2].name;
    }

    let response = await fetch(downloadUrl);
    let blob = await response.blob();
    let objectUrl = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = objectUrl;
    a.download = downloadName;

    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      hide();
      message.success("下载成功");
      document.body.removeChild(a);
    }, 1000);
  };

  //视频大小变化
  onVideoSizeChange = (v) => {
    const { rowOrClo, protoWidth, protoHeight, playUrlS } = this.state
    this.setState(
      {
        videoSize: v,
      },
      () => {
        this.mySelect.current.blur();
        let rowOrClos
        if (protoWidth >= protoHeight) {
          rowOrClos = '1'

        } else {
          rowOrClos = '2'
        }
        this.setState({
          rowOrClo: rowOrClos
        }, () => {
          if (this.state.videoSize == 1 && playUrlS) {
            //console.log(1);
            this.setState({
              source: true
            })
          } else {
            this.setState({
              source: false
            })
          }
          this.calculateFun();
        })


      }
    );
  };

  // 鼠标移动事件
  handleVideoBoxOver = (e) => {
    this.videoBoxOverCan(e);
  };

  // 鼠标移动事件
  videoBoxOverCan = (e) => {
    e.preventDefault();
    let osmall = this.videoBox.current;
    let evt = e || window.event;
    /*用于保存小的div拖拽前的坐标*/
    osmall.startX = evt.pageX - osmall.offsetLeft;
    osmall.startY = evt.pageY - osmall.offsetTop;

    /*鼠标的移动事件*/
    document.onmousemove = (e) => {
      let boxLeft = e.clientX - this.videoBox.current.offsetLeft
      let boxRight = e.clientX - this.videoBox.current.offsetLeft - this.videoBox.current.offsetWidth
      let boxTop = e.clientY - this.videoBox.current.offsetTop
      let boxBottom = e.clientY - this.videoBox.current.offsetTop - this.videoBox.current.offsetHeight
      if (boxLeft >= 0 & boxTop >= 0 & boxRight <= 0 & boxBottom <= 0) {
        this.setState(
          {
            keyCanPress: true
          }
        )
      } else {
        this.setState(
          {
            keyCanPress: false
          }
        )
      }

      let beforLeft =
        ((e.screenX - osmall.offsetLeft) / this.state.topVideoWidth) * 100;
      if (beforLeft <= 0) {
        beforLeft = 0;
      } else if (beforLeft >= 100) {
        beforLeft = 100;
      }
      var nowLeft = beforLeft + "%";
      let left = e.pageX - osmall.offsetLeft; //鼠标按下相对于video-box的Y的位置
      let top = e.pageY - osmall.offsetTop; //鼠标按下相对于video-box的X的位置
      this.setState(
        {
          video_box_left: left,
          video_box_top: top,
        },
        () => {
          let border_width =
            this.state.video_box_left -
              this.state.Check_box_border_offsetLefg >=
              0
              ? this.state.video_box_left -
              this.state.Check_box_border_offsetLefg
              : 0;

          let border_top =
            this.state.video_box_top - this.state.Check_box_border_offsetTop >=
              0
              ? this.state.video_box_top - this.state.Check_box_border_offsetTop
              : 0;

          if (this.state.canBeUse) {
            if (this.state.moveTrue) {
              this.setState({
                translateX: left,
              });
            }
          } else {
            if (this.state.Check_box_border_is_show) {
              this.setState({
                Check_box_border_width: border_width,
                Check_box_border_hight: border_top,
              });
            }
          }
        }
      );
    };
  };

  // 鼠标按下事件
  videoBoxDown = (e) => {
    e = e || window.event;
    if (this.state.canBeUse) {
      this.setState({
        moveTrue: true,
        downTrue: true,
      });
      return;
    }
    this.handleVideoBoxDown(e);
    e.preventDefault();
    let osmall = this.videoBox.current;
    let evt = e || window.event;
    /*用于保存小的div拖拽前的坐标*/
    const osmallX = evt.pageX - osmall.offsetLeft;
    const osmallY = evt.pageY - osmall.offsetTop;
    this.setState({
      magnifier_OffY: osmallY,
      magnifier_OffX: osmallX,
    });
  };

  //鼠标按下执行事件
  handleVideoBoxDown = (e) => {
    this.setState(
      {
        magnifier_startX: e.pageX,
        magnifier_startY: e.pageY,
        magnifier_up: true,
        Check_box_border_show: "block",
        Check_box_border_is_show: true,
      },
      () => {
        let osmall = this.videoBox.current;
        let left = this.state.magnifier_startX - osmall.offsetLeft; //鼠标按下相对于video-box的Y的位置
        let top = this.state.magnifier_startY - osmall.offsetTop; //鼠标按下相对于video-box的X的位置

        this.setState({
          Check_box_border_offsetTop: top,
          Check_box_border_offsetLefg: left,
        });
      }
    );
  };

  // 鼠标离开事件
  videoBoxLeave = (e) => {
    if (!this.state.downTrue) {
      this.videoBoxUp(e);
    }
  };

  //鼠标抬起事件
  videoBoxUp = (e) => {
    if (!this.state.magnifier_up || this.state.canBeUse) {
      this.setState({
        moveTrue: false,
      });
      return;
    }
    this.handleVideoBoxUp(e);
  };

  //鼠标抬起执行事件
  handleVideoBoxUp = (e) => {
    this.setState(
      {
        magnifier_width: this.state.Check_box_border_width,
        magnifier_hight: this.state.Check_box_border_hight,
        Check_box_border_show: "none",
        Check_box_border_offsetTop: 0,
        Check_box_border_offsetLefg: 0,
      },
      () => {
        this.setState(
          {
            magnifier_show: true,
            Check_box_border_is_show: false,
          },
          () => {
            this.jietu();
          }
        );

        document.onmouseout = () => {
          document.onmousemove = null;
          document.onmousedown = null;
        };
      }
    );
  };

  videoBoxEnter = (e) => {
    if (this.state.downTrue) {
      this.handleVideoBoxOver(e);
    }
  };

  //canvas截图
  jietu = () => {
    const {
      magnifier_OffY,
      magnifier_OffX,
      Check_box_border_width,
      Check_box_border_hight,
      magnifier_size,
      playUrlS,
    } = this.state;
    var videoA = document.getElementsByTagName("video")[0];
    var videoB = document.getElementsByTagName("video")[1];

    var canvasA = document.getElementById("canvasA");
    var canvasB = document.getElementById("canvasB");

    var cobjA = canvasA.getContext("2d"); //获取绘图环境
    var cobjB = canvasB.getContext("2d"); //获取绘图环境

    let width = magnifier_size
      ? Check_box_border_width
      : Check_box_border_width * 2;
    let height = magnifier_size
      ? Check_box_border_hight
      : Check_box_border_hight * 2;


    let arg = this.state.topVideoWidth / this.state.protoWidth
    let magnifier_Offx = magnifier_OffX / arg
    let magnifier_Offy = magnifier_OffY / arg
    let Check_box_border_widths = Check_box_border_width / arg
    let Check_box_border_hights = Check_box_border_hight / arg
    //console.log(arg);
    cobjA.drawImage(
      videoA,
      magnifier_Offx,
      magnifier_Offy,
      Check_box_border_widths,
      Check_box_border_hights,
      0,
      0,
      width,
      height
    );
    cobjB.drawImage(
      videoB,
      magnifier_Offx,
      magnifier_Offy,
      Check_box_border_widths,
      Check_box_border_hights,
      0,
      0,
      width,
      height
    );

    if (playUrlS) {
      var videoS = document.getElementsByTagName("video")[2];
      //console.log(videoS);
      var canvasS = document.getElementById("canvasS");
      var cobjS = canvasS.getContext("2d"); //获取绘图环境
      cobjS.drawImage(
        videoS,
        magnifier_Offx,
        magnifier_Offy,
        Check_box_border_widths,
        Check_box_border_hights,
        0,
        0,
        width,
        height
      );
      const canvasSurl = canvasB.toDataURL("image/png");

      this.setState({
        imgSurl: canvasSurl,
      });
    }

    const canvasAurl = canvasA.toDataURL("image/png");
    const canvasBurl = canvasB.toDataURL("image/png");

    this.setState({
      imgAurl: canvasAurl,
      imgBurl: canvasBurl,
    });
  };

  //视频对比方式改变
  changeSize = () => {
    this.setState(
      {
        magnifier_size: !this.state.magnifier_size,
      },
      () => {
        this.jietu();
      }
    );
  };

  // 全屏缩放
  screenShowChange = () => {
    const { screenFullShow } = this.state;
    this.setState(
      {
        screenFullShow: !screenFullShow,
      },
      () => {
        !screenFullShow ? this.fullScreen() : this.exitScreen();
        this.calculateFun();
      }
    );
  };

  //开启全屏模式
  fullScreen = () => {
    var el = document.documentElement;
    var rfs =
      el.requestFullScreen ||
      el.webkitRequestFullScreen ||
      el.mozRequestFullScreen ||
      el.msRequestFullscreen;
    if (typeof rfs != "undefined" && rfs) {
      rfs.call(el);
    }
    return;
  };

  //关闭全屏模式
  exitScreen = () => {
    document.exitFullscreen();
  };

  onProtoShow = () => {
    this.setState(
      {
        protoShow: !this.state.protoShow,
      },
      () => {
        this.calculateFun();
      }
    );
  };

  // 截图下载
  onDowlodeImg = () => {
    const { ossFileList, playIndex, imgAurl, imgBurl, imgSurl, playUrlS } =
      this.state;

    const hash = createHash(12);
    const AdownloadName = ossFileList[playIndex][0].name + "_a" + hash + ".png";

    const BdownloadName = ossFileList[playIndex][1].name + "_b" + hash + ".png";

    downloadFile(imgAurl, AdownloadName);
    downloadFile(imgBurl, BdownloadName);
    if (playUrlS) {
      const SdownloadName =
        ossFileList[playIndex][2].name + "_c" + hash + ".png";
      downloadFile(imgSurl, SdownloadName);
    }
  };

  getValue = (e) => {
    let timeResult = e.target.value * 1;


    this.playerB.seek(timeResult);
    this.playerA.seek(timeResult);
    setTimeout(() => {
      let timeA = this.playerA.getState().player.buffered;
      let timeB = this.playerB.getState().player.currentTime;
    }, 500);
  };

  // C视频展示和隐藏
  sourceShow = (params) => {

    const { videoSize, playUrlS, source } = this.state
    if (videoSize != 0) {
      return;
    }
    if (!playUrlS) {
      message.error({
        style: {
          marginTop: "20vh",
        },
        content: "没有找到C视频 , 请增添C视频",
      });
      this.setState({
        source: false,
      }, () => {
        //console.log(source);
      });
    } else {
      if (videoSize == 1) {
        this.setState({
          source: true,
        }, () => {
          //console.log(source);
        });
      } else {
        this.setState({
          source: !source,
        }, () => {
          //console.log(source);
        });
      }

    }
  }

  render() {
    const {
      isOn,
      playUrlA,
      playUrlB,
      playUrlS,
      videoProgressSuccess,
      topVideoWidth,
      topVideoHeight,
      videoSize,
      typeBox,
      getTypes,
      magnifier_show,
      magnifier_width,
      magnifier_hight,
      Check_box_border_offsetTop,
      Check_box_border_offsetLefg,
      Check_box_border_width,
      Check_box_border_hight,
      Check_box_border_show,
      magnifier_OffY,
      magnifier_OffX,
      source,
      magnifier_size,
      screenFullShow,
      protoHeight,
      protoWidth,
      bodyClientWidth,
      bodyClientHeight,
      boxWidth,
      protoShow,
      playerHeigt,
      playerwidth,
      contrlShow,
      rowOrClo,
      AIndex,
      BIndex,
      CIndex,
      videoTagList = [],
    } = this.state;

    var that = this;

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">视频A</Menu.Item>
        <Menu.Item key="2">视频B</Menu.Item>
        {playUrlS ? <Menu.Item key="3">视频C</Menu.Item> : <></>}
      </Menu>
    );

    function onLeftScoreChange(e) {
      const { nowVideoIndex, ossFileList } = that.state;
      that.setState({
        scoreLeft: e,
      });
      ossFileList[nowVideoIndex][0].a_val = e;
    }

    function onRightScoreChange(e) {
      const { nowVideoIndex, ossFileList } = that.state;
      that.setState({
        scoreRight: e,
      });
      ossFileList[nowVideoIndex][1].b_val = e;
    }
    // 切换下一组视频
    function clickNextVideo() {
      that.changeVideo(that.state.playIndex + 1);
    }
    // 切换上一组视频
    function clickPreVideo() {
      that.changeVideo(that.state.playIndex - 1);
    }

    function loadMore() {
      // this.list()
    }

    function onClickItem(clickId) {
      //点击列表的某一项，需要切换显示
      that.changeVideo(clickId);
    }

    function onChange(value) {
      sessionStorage.setItem("choiceType", JSON.stringify(value.join("_")));
    }


    const rowOrCloChange = (e) => {
      // //console.log('radio checked', e.target.value);
      that.setState({
        rowOrClo: e.target.value
      }, () => {
        that.calculateFun()
      })
    };

    const plainOptions = ["滑动对比", "拼接对比"];
    const options = [
      { label: "滑动对比", value: "1" },
      { label: "拼接对比", value: "2" },
    ];
    let VideoLeftBoxStyle = videoSize == 1 || videoSize == 2
      ? { position: "relative" }
      : { width: this.state.translateX }
    let VideoRightBoxStyle = {}
    let VideoBottomBoxStyle = { display: source ? "block" : "none" }
    let VideoLeftBoxClassName = "video-top-box"
    let VideoRightBoxClassName = "video-bottom-box"
    let VideoBottomBoxClassName = videoSize == 0 ? "sourse" : "pinjie-sourse"

    let VideoLeftStyle = {
      width: this.state.topVideoWidth,
      height: this.state.topVideoHeight,
    }


    let VideoTagLeftBoxStyle = {}
    let VideoTagRightBoxStyle = videoSize == 1 || videoSize == 2
      ? { left: "0%" }
      : { left: this.state.translateX }
    let VideoTagBottomBoxStyle = {
      width: this.state.topVideoWidth,
      height: this.state.topVideoHeight,
    }
    let VideoTagLeftBoxClassName = "video-shade"
    let VideoTagRightBoxClassName = 'video-shade'
    let VideoTagBottomBoxClassName = ['video-shade', videoSize == 0 ? "sourse-mask" : "pinjie-sourse-mask"].join(" ")


    let VideoRightStyle = {
      width: this.state.topVideoWidth,
      height: this.state.topVideoHeight,
    }

    let VideoBottomStyle = {}

    let VideoLeftClassName = "video-top-box-div"
    let VideoRightClassName = ''
    let VideoBottomClassName = ''


    const getName = (originName) => {
      const name = originName?.split('.')?.[0] || undefined
      if (name) {
        const tag = name.split('_');
        if (tag?.length > 1) {
          return tag[tag?.length - 1]
        }
      }
      return '--'
    }

    const playerStyleInsList = [
      {
        VideoBoxStyle: VideoLeftBoxStyle,
        VideoBoxClassName: VideoLeftBoxClassName,
        VideoStyle: VideoLeftStyle,
        VideoClassName: VideoLeftClassName,
        VideoTagStyle: VideoTagLeftBoxStyle,
        VideoTagClassName: VideoTagLeftBoxClassName


      },
      {
        VideoBoxStyle: VideoRightBoxStyle,
        VideoBoxClassName: VideoRightBoxClassName,
        VideoStyle: VideoRightStyle,
        VideoClassName: VideoRightClassName,
        VideoTagStyle: VideoTagRightBoxStyle,
        VideoTagClassName: VideoTagRightBoxClassName
      },
      {
        VideoBoxStyle: VideoBottomBoxStyle,
        VideoBoxClassName: VideoBottomBoxClassName,
        VideoStyle: VideoBottomStyle,
        VideoClassName: VideoBottomClassName,
        VideoTagStyle: VideoTagBottomBoxStyle,
        VideoTagClassName: VideoTagBottomBoxClassName
      },

    ]
    return (
      <div className="content">
        {/* 左边list` */}
        <div className={["left", videoSize == 2 ? "is-none" : ""].join(" ")}>
          <ListHeader
            index="序号"
            name="文件名"
            leftScore="打分 A"
            rightScore="打分 B"
          />

          <InfiniteScroll
            className="listLayout"
            initialLoad={false}
            pageStart={0}
            useWindow={false}
            loadMore={loadMore}
          >
            <List
              bordered={true}
              split={true}
              dataSource={this.state.ossFileList}
              renderItem={(item, index) => (
                <OssItem
                  key={index}
                  index={index}
                  name={item[0].name}
                  clickItemCallback={onClickItem}
                  select={item[0].name == this.state.fileName}
                  leftScore={item[0].a_val}
                  rightScore={item[1].b_val}
                />
              )}
            ></List>
          </InfiniteScroll>
        </div>
        {/* 右边 */}
        <div
          id="right"
          className={["right", videoSize == 2 ? "is-full-page" : ""].join(" ")}
        >
          {/* 右上对比方式 */}
          <div style={{ marginBottom: "10px" }}>
            <div>
              <Text>视频对比方式：</Text>
              {typeBox.length > 0 ? (
                <Select
                  defaultValue={typeBox[0].value}
                  style={{ width: 120 }}
                  onChange={this.onVideoSizeChange}
                  ref={this.mySelect}
                >
                  {typeBox.map((item, index) => {
                    return (
                      <Select.Option value={item.value} key={index}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', margin: '8px 0' }} >
            {videoTagList?.length >= 1 ? (
                <div style={{ marginRight: '10px' }}>
                  <Text className="select-title">视频A：</Text>
                  <Select
                    defaultValue={videoTagList[0].url}
                    style={{ width: 120 }}
                    onChange={(value) => {
                      this.setState({
                        playUrlA: value
                      }, () => {
                        // this.getWaitingTime();
                        console.log("==wz: change playUrlA", value)
                      });
                    }}
                  >
                    {videoTagList?.map((item, index) => (
                      <Select.Option value={item?.url} key={`${item?.name}-A-${index}`}>
                        {getName(item?.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : (
                ""
              )}
              {videoTagList?.length >= 2 ? (
                <div style={{ marginRight: '10px' }}>
                  <Text className="select-title">视频B：</Text>
                  <Select
                    defaultValue={videoTagList[1].url}
                    style={{ width: 120 }}
                    onChange={(value) => {
                      this.setState({
                        playUrlB: value
                      });
                    }}
                  >
                    {videoTagList?.map((item, index) => (
                      <Select.Option value={item?.url} key={`${item?.name}-B-${index}`}>
                        {getName(item?.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : (
                ""
              )}
              
              {videoTagList.length >= 3 ? (
                <div style={{ marginRight: '10px' }}>
                  <Text className="select-title">视频C：</Text>
                  <Select
                    defaultValue={videoTagList[2].url}
                    style={{ width: 120 }}
                    onChange={(value) => {
                      this.setState({
                        playUrlS: value
                      });
                    }}
                  >
                    {videoTagList?.map((item, index) => (
                      <Select.Option value={item?.url} key={`${item?.name}-C-${index}`}>
                        {getName(item?.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : (
                ""
              )}


            </div>
            {

              videoSize == 1 ?

                <Radio.Group onChange={rowOrCloChange} value={rowOrClo} className="radio_group" >
                  <Radio value={"1"} >纵向拼接</Radio>
                  <Radio value={"2"} >横向拼接</Radio>

                </Radio.Group> : ""
            }
            {getTypes !== "滑动对比_拼接对比" &&
              getTypes !== "滑动对比" &&
              getTypes !== "拼接对比" ? (
              <div>
                <Text>对比方式：</Text>
                <Checkbox.Group
                  options={plainOptions}

                  onChange={(value) => onChange(value)}
                />
              </div>
            ) : (
              ""
            )}


          </div>

          <div>
            <div
              style={
                screenFullShow
                  ? {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: playerHeigt,
                    width: playerwidth,
                    background: "#000",
                    zIndex: 9999,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center",
                    overflow: !protoShow ? "hidden" : "",
                  }
                  : {}
              }
            >
              {/* 视频区域 */}
              <div


                ref={this.videoBox}
                onMouseOver={(e) => this.handleVideoBoxOver(e)}
                onMouseLeave={(e) => {
                  this.videoBoxLeave(e);
                }}
                onMouseDown={(e) => this.videoBoxDown(e)}
                onMouseUp={(e) => this.videoBoxUp(e)}
                onMouseEnter={(e) => {
                  this.videoBoxEnter(e);
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                className={[
                  "video-box",
                  (videoSize == 1 || videoSize == 2) &&
                    that.state.rowOrClo == '1'
                    ? "video-box-active"
                    : "",
                  (videoSize == 1 || videoSize == 2) &&
                    that.state.rowOrClo == '2'
                    ? "video-box-active-other"
                    : "",
                ].join(" ")}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                style={
                  {
                    backgroundColor: "#000",

                    width: screenFullShow ? boxWidth : this.state.oldVideoWidth
                  }

                }
              >
                <div className="video-shade"></div>
                {/* 视频A，固定在top层，通过滑动，改变宽度 */}

                <div
                  className={playerStyleInsList[AIndex]['VideoBoxClassName']}
                  style={playerStyleInsList[AIndex]['VideoBoxStyle']}
                >
                  <div

                    className={playerStyleInsList[AIndex]['VideoClassName']}
                    style={playerStyleInsList[AIndex]['VideoStyle']}
                  >
                    <Player
                      ref={(player) => {
                        this.playerA = player;
                      }}
                      src={playUrlA}
                      fluid={false}
                      width={this.state.topVideoWidth}
                      height={this.state.topVideoHeight}
                      aspectRatio="auto"
                      // autoPlay={true}
                      preload="auto"
                    ></Player>
                  </div>
                  <div className={playerStyleInsList[AIndex]['VideoTagClassName']}
                    style={playerStyleInsList[AIndex]['VideoTagStyle']} >
                    <p>视频A</p>
                  </div>
                </div>

                {/* 视频B，在bottom层 */}
                <div

                  className={playerStyleInsList[BIndex]['VideoBoxClassName']}
                  style={playerStyleInsList[BIndex]['VideoBoxStyle']}
                >
                  <div

                    className={playerStyleInsList[BIndex]['VideoClassName']}
                    style={playerStyleInsList[BIndex]['VideoStyle']}
                  >
                    <Player
                      ref={(player) => {
                        this.playerB = player;
                      }}
                      src={playUrlB}
                      fluid={false}
                      width={this.state.topVideoWidth}
                      height={this.state.topVideoHeight}
                      // autoPlay={true}
                      preload="auto"
                    ></Player>

                  </div>
                  <div
                    className={playerStyleInsList[BIndex]['VideoTagClassName']}
                    style={playerStyleInsList[BIndex]['VideoTagStyle']}
                  >
                    <p>视频B</p>
                  </div>
                </div>

                {/* C视频 */}
                <div
                  className={playerStyleInsList[CIndex]['VideoBoxClassName']}
                  style={playerStyleInsList[CIndex]['VideoBoxStyle']}
                >
                  <div
                    className={playerStyleInsList[CIndex]['VideoClassName']}
                    style={playerStyleInsList[CIndex]['VideoStyle']}
                  >
                    <Player
                      ref={(player) => {

                        this.playerS = player;
                      }}
                      src={playUrlS}
                      fluid={false}
                      width={this.state.topVideoWidth}
                      height={this.state.topVideoHeight}
                      // autoPlay={true}
                      preload="auto"
                    ></Player>

                  </div>
                  <div
                    className={playerStyleInsList[CIndex]['VideoTagClassName']}
                    style={playerStyleInsList[CIndex]['VideoTagStyle']}
                    onClick={(e) => {
                      e.preventDefault();
                      return null;
                    }}

                  >
                    <p>视频C</p>
                  </div>
                </div>


                {/* 滑动竖线，改变视频A的宽度 */}
                {videoSize == 0 ? (
                  <div
                    className="Y-axis-top"
                    ref={this.yMove}
                    onMouseDown={(e) => this.small_down(e)}
                    style={{
                      left: this.state.translateX,
                      visibility: this.state.Yvisibility,
                    }}
                  >
                    <div className="div-round">
                      <img className="move-icon" src={moveLeftIcon} alt="" />
                      <img
                        className="move-icon move-right-icon"
                        src={moveLeftIcon}
                        alt=""
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {videoProgressSuccess == false ? (
                  <div className="is-progress-style">
                    <p>视频加载中......</p>
                  </div>
                ) : (
                  ""
                )}
                {/* 视频选框 */}
                <div
                  className="pre_border"
                  style={{
                    top: Check_box_border_offsetTop,
                    left: Check_box_border_offsetLefg,
                    width: Check_box_border_width,
                    height: Check_box_border_hight,
                    display: Check_box_border_show,
                  }}
                ></div>


              </div>
              {/* 视频进度条 */}
              {videoProgressSuccess == true ? (
                <div
                  className="video-control"
                  id="videoControl"
                  style={
                    screenFullShow
                      ? {
                        width: bodyClientWidth,
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        zIndex: 999,
                        visibility: contrlShow,
                      }
                      : { width: this.state.oldVideoWidth }
                  }
                >
                  <div className="pull-left fontzero control-leftview">
                    {/* <!--暂停/播放键--> */}
                    <div
                      className="control-btn playbtn"
                      id="playPausebtn"
                      onClick={this.setVideos}
                    >
                      {isOn ? (
                        <img
                          className="video-play-img"
                          src={videoPlay}
                          alt=""
                        />
                      ) : (
                        <img
                          className="video-play-img"
                          src={videoPause}
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  <div className="pull-left fontzero progress-box">
                    <div className="progress-box-body">
                      {/* <!--播放时长--> */}
                      <p className="pro_pone">{this.state.vcCurrentTime}</p>

                      <div className="progress_bar_box">
                        <Slider
                          defaultValue={0}
                          max={this.state.videoAllSed}
                          value={this.state.videoNowSed}
                          step={0.1}
                          onChange={(e) => this.changeSed(e)}
                          tooltipPlacement="none"
                        />
                      </div>

                      {/* <!--总时长--> */}
                      {this.state.vcTotalTime == "00:00" ? (
                        ""
                      ) : (
                        <p className="pro_pone">{this.state.vcTotalTime}</p>
                      )}
                    </div>
                  </div>

                  <div
                    className="screen-full-icon"
                    style={
                      screenFullShow
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    onClick={this.onProtoShow}
                  >
                    {<RetweetOutlined />}
                  </div>
                  <div
                    className="screen-full-icon"
                    onClick={this.screenShowChange}
                  >
                    {screenFullShow ? (
                      <FullscreenExitOutlined />
                    ) : (
                      <FullscreenOutlined />
                    )}
                  </div>

                  <div
                    className="screen-full-icon"
                    style={
                      screenFullShow
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    onClick={clickNextVideo}
                  >
                    {<ArrowDownOutlined />}
                  </div>

                  <div
                    className="screen-full-icon"
                    style={
                      screenFullShow
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    onClick={clickPreVideo}
                  >
                    {<ArrowUpOutlined />}
                  </div>

                  {/* 下载视频 */}
                  <Dropdown overlay={menu}>
                    <Button
                      style={{ display: screenFullShow ? "none" : "block" }}
                    >
                      下载 <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Alert
            style={{ margin: "20px 0px" }}
            message="视频质量主观评价：
                        请根据您的个人观感对视频进行打分， 质量较好的为5分，另一个根据差距程度打0-5分。
                        左上为A、右下为B。"
          ></Alert>

          <div className="scoreLayout">
            <Text>视频A：</Text>
            <InputNumber
              id="inputA"
              type="number"
              onChange={onLeftScoreChange}
              value={this.state.scoreLeft}
              max={5}
              min={0}
            />

            <Text>视频B：</Text>
            <InputNumber
              type="number"
              onChange={onRightScoreChange}
              value={this.state.scoreRight}
              max={5}
              min={0}
            />

            <Button type="primary" onClick={clickNextVideo}>
              下一组视频
            </Button>

            <Button type="default" onClick={clickPreVideo}>
              上一组视频
            </Button>
            {videoSize == 0 ?
              (<Button type={source ? "primary" : "default"} onClick={this.sourceShow}>
                {source ? "C!隐藏" : "C!显示"}
              </Button>
              ) : ''
            }

            <input
              onBlur={(e) => {
                this.getValue(e);
              }}
            ></input>
            <span style={{ color: "red" }}>{this.state.alertTitle}</span>
          </div>
          <div className="description">
            <h4>使用说明</h4>
            <ul>
              <li>
                <a href='https://yuque.antfin-inc.com/pf01040913/qlme58/pt8sk6hrqqubwvuq' target="_blank">使用文档</a>
              </li>
              <li>
                s265编码视频，请将浏览器升级为106及以上版本，方可播放。
              </li>
              <li>
                快捷键的使用（当鼠标进入视频范围内可使用快捷键）：
                <ul>
                  <li>
                    c键:进入截图模式，鼠标左键在视频区域按下，拉选框，鼠标左键抬起结束选框，显示截图。
                  </li>
                  <li>
                    q键：退出截图展示，返回视频播放页面。
                  </li>
                  <li>
                    L键：按下，显示c视频，抬起，显示A/B视频。
                  </li>
                  <li>
                    exc键：退出全屏模式。
                  </li>
                  <li>
                    1键(键盘左上角)：显示滑动模式。
                  </li>
                  <li>
                    2键(键盘左上角)：显示拼接模式
                  </li>
                  <li>
                    tab键：横向拼接和纵向拼接切换
                  </li>

                </ul>
              </li>
            </ul>
          </div>


        </div>

        {/* 放大镜 */}
        <div
          className="magnifier-bg"
          style={{
            width: document.body.clientWidth,
            height: document.body.clientHeight,
            display: magnifier_show,

          }}
        >
          <div className="magnifier-top">
            <p className="magnifier-title">按Q键退出</p>

            <p className="magnifier-icon">
              <Button
                style={{ marginRight: "20px" }}
                type="primary"
                onClick={() => {
                  this.changeSize();
                }}
                icon={
                  magnifier_size ? (
                    <PlusCircleTwoTone className="plusCircleTwoTone" />
                  ) : (
                    <MinusCircleTwoTone className="plusCircleTwoTone" />
                  )
                }
                size="middle"
              />

              <Button
                onClick={this.onDowlodeImg}
                type="primary"
                icon={<DownloadOutlined />}
                size="middle"
              />
            </p>
          </div>

          <div className="magnifier-content">

            <div
              className="magnifier-left"
              style={{
                width: magnifier_size ? magnifier_width : magnifier_width * 2,
                height: magnifier_size ? magnifier_hight : magnifier_hight * 2,
              }}
            >
              <div className="magnifier-mask">a</div>
              <div className="magnifier-box">
                <canvas
                  id="canvasA"
                  width={magnifier_size ? magnifier_width : magnifier_width * 2}
                  height={
                    magnifier_size ? magnifier_hight : magnifier_hight * 2
                  }
                ></canvas>
              </div>
            </div>

            <div
              className="magnifier-left"
              style={{
                width: magnifier_size ? magnifier_width : magnifier_width * 2,
                height: magnifier_size ? magnifier_hight : magnifier_hight * 2,
              }}
            >
              <div className="magnifier-mask">b</div>
              <div className="magnifier-box">
                <canvas
                  id="canvasB"
                  width={magnifier_size ? magnifier_width : magnifier_width * 2}
                  height={
                    magnifier_size ? magnifier_hight : magnifier_hight * 2
                  }
                ></canvas>
              </div>
            </div>

            <div
              className="magnifier-left"
              style={{
                display: playUrlS ? "block" : "none",
                width: magnifier_size ? magnifier_width : magnifier_width * 2,
                height: magnifier_size ? magnifier_hight : magnifier_hight * 2,
              }}
            >
              <div className="magnifier-mask">c</div>
              <div className="magnifier-box">
                <canvas
                  id="canvasS"
                  width={magnifier_size ? magnifier_width : magnifier_width * 2}
                  height={
                    magnifier_size ? magnifier_hight : magnifier_hight * 2
                  }
                ></canvas>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default HomeContent;
