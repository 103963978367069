import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
 
  var URL = window.location.href;
  var URL_ARRY = URL.split("?");

  let URL_PARAMS=localStorage.getItem('urls')


 if (URL_PARAMS==='undefined'||URL_PARAMS==='null'||URL_PARAMS===null) {
  localStorage.setItem("urls", URL_ARRY[1]);
 }else{ 
  localStorage.setItem("urls", URL_PARAMS);
 }
 
 
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("TOKEN_LOGIN") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
