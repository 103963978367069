import { Button, message,  Spin } from "antd";
import React from "react";
import Constant from "../../Constant";
import ScoreManager from "../score/ScoreManager";
 

class Submit extends React.Component {
    state = {
        submitting: false
    }

    render() {
        let that = this;

        function onClickSubmit(params) {
            if (ScoreManager.instance.map2txt() == "") {
                message.warning("请先进行打分")
                that.setState({
                    submitting: false,
                })
                return;
            }

            that.setState({
                submitting: true,
            })

            var user = localStorage.getItem(Constant.KEY_USER);
            if (user == undefined) {
                message.warn("请输入您的姓名/昵称")
                that.setState({
                    submitting: false
                })
                return;
            }

            ScoreManager.instance.email(user, function (response) {
                that.setState({
                    submitting: false
                })
                if (response.code == 200) {
                    //发送邮件成功
                    message.info("分数已提交")
                } else {
                    // 发送邮件失败
                    message.warn("[" + response.code + "]" + response.msg);
                }
            });
        }
        return <div>
            <Button type="primary" onClick={onClickSubmit}>{
                this.state.submitting ? "正在提交" : "提交分数"}</Button>
            {this.state.submitting && <Spin></Spin>}
        </div>
    }
}

export default Submit;