import { Input, Space, Button } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import Constant from "../../Constant";
import Submit from "../../components/submit/Submit";
import { withRouter } from "react-router-dom";

require("./NormalHeader.css");

class NormalHeader extends React.Component {
  state = {
    isSubmiting: false,
    warrning: false,
  };

  oldUsername = "";

  constructor(props) {
    super(props);
    let username = localStorage.getItem(Constant.KEY_USER);
    if (username) {
      this.oldUsername = username;
    }
  }

  render() {
    let that = this;
    function onUsernameChange(e) {
      if (e.target.value && e.target.value != that.oldUsername) {
        that.oldUsername = e.target.value;
        localStorage.setItem(Constant.KEY_USER, that.oldUsername);
      }
    }

    function clickLogout() {
      that.props.history.push("/login");
      localStorage.clear();
    }

    return (
      <Space>
        <Button type="default" onClick={clickLogout} style={{marginRight:'50px'}}>
          退出登录
        </Button>
        <Text className="ttittle">评测员：</Text>

        <Input
          key="user"
          className="username"
          placeholder="请输入您的姓名/昵称"
          defaultValue={this.oldUsername}
          type="text"
          onChange={onUsernameChange}
        />

        <Submit key="normal_submit"></Submit>
      </Space>
    );
  }
}

export default withRouter(NormalHeader);
