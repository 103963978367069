
import React from "react";

require("./ListHeader.css");

class ListHeader extends React.Component {

    render() {
        return (
            <div className="hlayout">
                <p className="hindex">{this.props.index}</p>

                <p className="hname">{this.props.name}</p>

                <p className="hscore">{this.props.leftScore}</p>

                <p className="hscore">{this.props.rightScore}</p>

                {/* <img width="16px" height="16px" src={this.props.select ? imgArrow : imgUnselect}></img> */}
            </div>
        )
    }
}

export default ListHeader;