import { Layout } from "antd";
import React from "react";
import Constant from "../../Constant";//程序常用的一些常量
import HomeContent from "../home/HomeContent";//视频内容


import AdminHeader from "./AdminHeader";//登录头部

import './Admin.css'

const { Header, Content } = Layout;

/** 
 * 管理员页面
 */
class Admin extends React.Component {

    state = {
        loginStatus: false,//是否登录
        dirname: Constant.DEFAULT_FOLDER//文件夹名称
    }

    constructor(props) {
        super(props);
        // 本地储存中获取登录状态
        let loginstatus = localStorage.getItem(Constant.KEY_LOGIN_STATUS);
        // 将登录状态改成true
        if (loginstatus) {
            this.state.loginStatus = true;
        }
        // 本地储存中获文件夹名称
        let dirname = localStorage.getItem(Constant.KEY_DIR);
        if (dirname) {
            // 将文件夹名称改成本地获取的
            this.state.dirname = dirname;
        }
    }
 
    render() {
        let that = this;
        // 登录状态回调函数
        function loginCallback(loginStatus) {
            that.setState({
                loginStatus: loginStatus
            })
        }
        // 文件夹名称变化回调函数
        function changeDirCallback(dirname) {
            that.setState({
                dirname
            })
        }

        return <Layout className="admin-layout">
            {/* 头部 */}
            <Header>
                <AdminHeader loginCallback={loginCallback} changeDirCallback={changeDirCallback} />
            </Header>
            {/* 内容 */}
            <Content className="admin-content">
                 <HomeContent folder={this.state.dirname} />
            </Content>
        </Layout>
    }
}

export default Admin;