import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/login/index";
import Index from "./pages/admin/Admin";
import Normal from "./pages/normal/Normal";
import { withRouter } from "react-router-dom";

class App extends React.Component {
  getRightComponent = () => {
    let urlsparm = localStorage.getItem("urls");
    if (urlsparm === "undefined") {
      return <Index></Index>;
    } else {
      return <Normal></Normal>;
    }
  };
  render() {
    return (
      <div className="app-page" style={{ height: "100%" }}>
        <Switch>
          <Route path="/login" component={Login}></Route>

          <PrivateRoute path="/" component={this.getRightComponent} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
