import React from "react";
import { useEffect } from 'react';
import { withRouter } from "react-router";
import { Form, Input, Button, Checkbox, message } from "antd";
import { setitem } from "../../utils/utils";
import Base64 from 'base-64';

class LoginForm extends React.Component {

  state = {
    userName: '',
    password: ''
  }

  componentDidMount() {
    let captcha;
    
    const getInstance = (instance) => {
      captcha = instance;
    };

    const onBizResultCallback = () => {
      console.log('onBizResultCallback');
    }

    const captchaVerifyCallback = async (captchaVerifyParam) => {
      try {
        let captchaResult = true;
        const response = await fetch('apis/api/account/login', {
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            accountId: this.state.userName,
            password: this.state.password,
            captchaVerifyParam: captchaVerifyParam,
          })
        })
        if (response.status === 200) {
          const ret_data = await response.json();
          if( ret_data.code === 200 ) {
            setitem('TOKEN_LOGIN', ret_data.token)
            this.props.history.push("/");
          }
          else if (ret_data.code === 500 ) {
            captchaResult = false;
          }
          else if (ret_data.code === 403 ) {
            message.error(ret_data.msg);
          }
          else
          {
            message.error("未知错误！")
          }
        }
        else
        {
            message.error("未知错误！")
        }
        let verifyResult = {
          captchaResult: captchaResult
        }
        return verifyResult;
      } catch (err) {
        let verifyResult = {
          captchaResult: true
        }
        message.error("server error");
        return verifyResult;
      }
    }

    window.initAliyunCaptcha({
      SceneId: '1vhv7mpr',
      prefix: '1sbtke',
      mode: 'popup',
      element: '#captcha-element',
      button: '#captcha-button',
      captchaVerifyCallback: captchaVerifyCallback,
      onBizResultCallback: onBizResultCallback,
      getInstance: getInstance,
      slideStyle: {
        width: 360,
        height: 40,
      },
      language: 'cn',
    });
  }

  componentWillUnmount() {
    const maskElement = document.getElementById('aliyunCaptcha-mask');
    if (maskElement) {
      maskElement.remove();
    }

    const popupElement = document.getElementById('aliyunCaptcha-window-popup');
    if (popupElement) {
      popupElement.remove();
    }
  }


  onRegister = (values) => {
    let data = { accountId: this.state.userName, password: this.state.password }
    fetch('apis/api/account/login', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => {
      return response.json()
    }
    ).then(res => {
      if (res.code === 200) {
        setitem('TOKEN_LOGIN', res.data)
        this.props.history.push("/");
      }
    }).catch(function (error) {
      console.log('There has been a problem with your fetch operation: ', error.message);
    })
  }


  render() {
    const onFinish = (values) => {

      let b = Base64.encode(values.password)
      this.setState({
        userName: values.username,
        password: b
      }, () => {

        // this.onRegister()

      });
    }

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="用户名"
          name="username"
          rules={[
            { required: true, message: "请输入用户 名" },
            { min: 8, message: "请输入八位以上的用户名" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="密码"
          name="password"
          rules={[
            { required: true, message: "请输入密码" },
            {
              pattern:
                "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
              message: "密码格式错误",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <div id="captcha-element"></div>
        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <div id="captcha-element"></div>
          <Button type="primary" htmlType="submit" id="captcha-button">
            登录
          </Button>
          <span
            style={{
              float: "right",
              verticalAlign: "bottom",
              color: "#1890ff",
            }}
            onClick={this.props.toggleShow}
          >
            注册
          </span>
        </Form.Item>
      </Form>
    );
  }
}

export default withRouter(LoginForm);
