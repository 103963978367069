import React from 'react'
import { Form, Input, Button, message } from "antd";
import Base64 from "base-64";




class RegisterForm extends React.Component {

  state = {
    userName: "",
    password: "",
  };

  onRegister = (values) => {
    let data = {
      accountId: this.state.userName,
      password: this.state.password,
    };
    fetch("apis/api/account/registered", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      // 注意 post 时候参数的形式
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.code === 200) {
          message.info(res.errorMessage)
          this.props.toggleShow()
        }else{      
          message.info(res.errorMessage)
        }
      })
      .catch(function (error) {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
      });
  };  
    
  render() {
    const onFinish = (values) => {
      let b = Base64.encode(values.password);
      this.setState(
        {
          userName: values.username,
          password: b,
        },
        () => {
          this.onRegister();
        }
      );
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Form
        name="basic"
        labelCol={{ span:6}}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="用户名"
          name="username"
          rules={[
              { required: true, message: "请输入用户 名" },
              { min: 8, message: "请输入八位以上的用户名" }
        ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="密码"
          name="password"
          rules={[
              { required: true, message: "请输入密码" },
              { pattern:'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$' , message: "密码格式错误" },
            ]}
        >

          <Input.Password />
        </Form.Item>


        <Form.Item
        name="confirm"
        label="重复密码"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: '填写密码',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('密码不一致'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Button type="primary" htmlType="submit">
            注册
          </Button>
          <span style={{float:'right' ,verticalAlign:'bottom',color:"#1890ff"}}   onClick={this.props.toggleShow}>返回登录</span>
        </Form.Item>
      </Form>
    );
  }
}

export default RegisterForm;