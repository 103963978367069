class Constant {
    static debug = false;
    
    


    // static BASE_URL = "http://localhost:8080/" //开发环境
    static BASE_URL = "http://26022811-1491884072224687.test.functioncompute.com/" //函数计算平台
    // static BASE_URL = "https://subject-eval-fc.aliyuncs.com/" //函数计算平台

    //localStorage key信息 start
    static KEY_ACCOUNT = "KEY_ACCOUNT"; 
    static KEY_PASSWORD = "KEY_PASSWORD";
    static KEY_USER = "KEY_USER";
    static KEY_LOGIN_STATUS = "KEY_LOGIN_STATUS";
    static KEY_DIR = "KEY_dir";
    static KEY_EMAIL = "KEY_EMAIL";
    //localStorage key信息 end

    //加解密钥
    static AES_128 = [1, 3, 1, 4, 1, 5, 9, 2, 6, 5, 8, 9, 12, 13, 14, 15];


    //默认文件夹
    static DEFAULT_FOLDER = "";
    static DEFAULT_EMAIL = "";


}

export default Constant;