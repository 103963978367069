import React from 'react'
import './index.css'

import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import { withRouter } from 'react-router-dom'

import {Card} from 'antd'







class Login extends React.Component {
    state = {
        show: 'login'    //当前展示的是登录框还是注册框
    }

    componentDidMount() {
        // 防止用户通过浏览器的前进后退按钮来进行路由跳转
        // 当用户登陆后再通过浏览器的后退按钮回到登录页时，再点击前进按钮可以直接回到首页
        if (true) {
            this.props.history.go(1)   //不然他后退或者后退了直接登出
            // logout()
        }
   
    }
    /**
     * 切换登录和注册的面板
     */
    toggleShow = () => {
        this.setState({
            show: this.state.show === 'login' ? 'register' : 'login'
        })
    }
    render() {
        const { show } = this.state
        return (
           
            <div className="wrapper">
            <Card title="视频对比" bordered={false} style={{ width: 500 }}>
                {show==='login'?<LoginForm toggleShow={this.toggleShow} />:<RegisterForm  toggleShow={this.toggleShow}/>}
              
            </Card>
          </div>
  
        )
    }
}

export default withRouter(Login)