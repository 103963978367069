
import Constant from "../../Constant";


var FileSaver = require("file-saver");

const KEY = "ScoreManager";

class ScoreManager {

    static instance = new ScoreManager();

    data = new Map();
    emailPath = Constant.DEFAULT_EMAIL;

    constructor() {
        var localData = localStorage.getItem(KEY);
    }

    /**
     * 记录文件及分数
     * @param {*} fileName 
     * @param {*} scoreLeft 
     * @param {*} scoreRight
     */
    put(fileName, scoreLeft, scoreRight) {
        var item = {
            name: fileName,
            left: scoreLeft,
            right: scoreRight
        }
        this.data.set(fileName, item);
    
       
    }

    

    /**
     * 获取某一个文件的分数
     * @param {*} fileName 
     */
    get(fileName) {
        return this.data.get(fileName);
    }
    /**
     * 设置Email地址
     * @param {*} email 
     */
    setEmail(email) {
        var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (pattern.test(email)) {
            this.emailPath = email
        }
    }

    /**
     * 把数据保存到本地
     */
    save(userName) {
        var txt = "";
        this.data.forEach(function (value, key) {
            txt += (value.name + " " + value.left + " " + value.right + "\n")
        })

        let blob = new Blob([txt], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, userName + "_score.txt");
    }

    map2txt() {
        var txt = "";
        this.data.forEach(function (value, key) {
            txt += (value.name + " " + value.left + " " + value.right + "\n")
        })
        return txt;
    }

    /**
     * 发送邮件
     * @param {*} userName 
     */
    async email(userName, sendCallback) {
        let txt = this.map2txt();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user: userName,
                content: txt,
                email: this.emailPath
            })
        };
        fetch(Constant.BASE_URL + "mail/send", requestOptions)
            .then(response => response.json())
            .then(data => {
                sendCallback && sendCallback(data);
            })
            .catch(e => {
                console.warn(e)
                const data = {
                    code: 400,
                    msg: "提交分数失败，请稍后重试"
                }
                sendCallback && sendCallback(data);
            });
    }

    uploadOss(userName) {
        console.log("upload oss");
        let txt = this.map2txt();
      
    }
}


export default ScoreManager;